import React from 'react'

const Social = () => {
  return (
    <div className='home__social'>
        <a href='https://www.instagram.com/crsthasubarna/' className='home__social-icon' target={"_blank"}>
        <i class="uil uil-instagram"></i>
        </a>
        <a href='https://www.facebook.com/subarna.shrestha.7712/' className='home__social-icon' target={"_blank"}>
        <i class="uil uil-facebook-f"></i>
        </a>
        <a href='https://github.com/Crstha' className='home__social-icon' target={"_blank"}>
        <i class="uil uil-github-alt"></i>
        </a>

    </div>
  )
}

export default Social