import Image1 from "../../assets/testimonial1.png";
import Image2 from "../../assets/testimonial2.png";
import Image3 from "../../assets/testimonial3.png";


export const Data = [
    {
        id:1,
        image:Image1,
        title:"Subarna Shrestha",
        description:"A really good job, all aspects of project were followed step by step with good outcome."
    },
    {
        id:2,
        image:Image2,
        title:"Bikash Karki",
        description:"A really good job, all aspects of project were followed step by step with good outcome."
    },
    {
        id:3,
        image:Image3,
        title:"Ram Ghimire",
        description:"A really good job, all aspects of project were followed step by step with good outcome."
    }
]